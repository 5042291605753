<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="'Whatsapp'"
                :subtitle="$tc('app.report', 2)"/>
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-12 col-xl-12">
          <div class="card card-tabs">
            <div class="card-header">
              <ul class="nav nav-tabs">
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'WhatsAppMetaStatReport' }"
                    class="nav-link"
                    replace
                  ><span class="material-symbols-outlined"> query_stats </span
                  >{{ $tc('app.stats', 2) }}
                  </router-link
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'WhatsAppMetaHistoryReport' }"
                    class="nav-link"
                    replace
                  ><span class="material-symbols-outlined"> history </span
                  >{{ $t('app.history') }}
                  </router-link
                  >
                </li>
                <!-- <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'WhatsAppMetaUsageReport' }"
                    class="nav-link"
                    replace
                    ><span class="material-symbols-outlined">
                      insert_chart </span
                    >{{ $tc('app.usage', 1) }}</router-link
                  >
                </li> -->
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'WhatsAppMetaCampaignReport' }"
                    class="nav-link"
                    replace
                  ><span class="material-symbols-outlined"> campaign </span
                  >{{ $tc('app.campaign', 2) }}
                  </router-link
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'WhatsAppMetaReportExports' }"
                    class="nav-link"
                    replace
                  ><span class="material-symbols-outlined">
                      cloud_download </span
                  >{{ $tc('app.file', 2) }}
                  </router-link
                  >
                </li>
                <div class="opts_card">
                  <a
                    style="display: flex; align-items: center"
                    href="javascript:void(0)"
                    @click="showFilter"
                  ><span class="material-symbols-outlined title">
                      filter_list
                    </span></a
                  >
                  <a
                    style="display: flex; align-items: center"
                    href="javascript:void(0)"
                    @click="reload"
                  ><span class="material-symbols-outlined title">
                      refresh
                    </span></a
                  >
                  <form class="form-inline calendar_form">
                    <date-range-picker
                      class="form-control"
                      :format="'YYYY-MM-DD'"
                      :options="options"
                      @change="changeDate"
                    />
                  </form>
                  <y-date-picker :type="'icon'" :iconClass="'calendar_icon'"
                                 :options="optionCalendar" :format="'YYYY-MM-DD'"
                                 @change="changeDate"></y-date-picker>
                  <dropdown tag="span">
                    <template #text>
                      <i class="fas fa-file-export title"></i>
                    </template>
                    <template #items>
                      <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="exportData('csv')"
                      >CSV</a
                      >
                      <a
                        v-if="$route.name === 'WhatsappStatReport'"
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="exportData('png')"
                      >PNG</a
                      >
                      <a
                        v-if="$route.name === 'WhatsappStatReport'"
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="exportData('svg')"
                      >SVG</a
                      >
                      <a
                        v-if="$route.name !== 'WhatsappStatReport'"
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="exportData('xlsx')"
                      >XLSX</a
                      >
                      <a
                        v-if="$route.name !== 'WhatsappStatReport'"
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="exportData('pdf')"
                      >PDF</a
                      >
                    </template>
                  </dropdown>
                </div>
              </ul>
            </div>
            <div class="card-body block-el">
              <router-view
                ref="view"
                :filters="form.filters"
                :startDate="form.date.gte"
                :endDate="form.date.lte"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <SidebarComponent ref="filter" title="Filtros">
      <InputComponent type="text" :label="$tc('app.recipient', 1)"
        placeholder="5511999999999" :value="filter.to" v-model="filter.to"></InputComponent>
      <InputComponent type="text" :label="$tc('app.sender', 1)"
        placeholder="5511999999999" :value="filter.sender"  v-model="filter.sender"></InputComponent>
      <SelectComponent v-model="filter.status" :label="$tc('app.status', 1)" :options="statuses"
                       multiple></SelectComponent>
      <SelectComponent v-model="filter.campaign" :label="$tc('app.campaign', 1)"
                       :options="campaigns" text-field="name"
                       value-field="id"></SelectComponent>
      <template #footer>
        <div class="d-flex justify-content-between">
          <ButtonComponent @click="cleanFilter" variant="secondary">Limpar</ButtonComponent>
          <ButtonComponent @click="applyFilter">Aplicar Filtros</ButtonComponent>
        </div>
      </template>
    </SidebarComponent>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Dropdown from '@/components/Dropdown';
import Tab from '@/directives/Tab';
import WhatsService from '@/services/whatsapp.service';
import YDatePicker from '@/components/DatePicker/YDatePicker.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import SidebarComponent from '@/components/SidebarComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';

export default {
  name: 'WhatsappDashboard',
  components: {
    InputComponent,
    ButtonComponent,
    SidebarComponent,
    SelectComponent,
    PageHeader,
    Dropdown,
    YDatePicker,
    // Card,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/whatsapp/reports/history',
        2: '/whatsapp/reports/history',
      },
      optionCalendar: {
        autoApply: true,
        startDate: moment().format('YYYY-MM-DD'),
      },
      statuses: [
        { text: this.$tc('app.sent', 1), value: 'SENT' },
        { text: this.$tc('app.delivered', 1), value: 'DELIVERED' },
        { text: this.$tc('app.read', 1), value: 'READ' },
        { text: this.$tc('app.failed', 1), value: 'FAILED' },
      ],
      campaigns: [],
      filter: {
        status: [],
        from: '',
        to: '',
        campaign: '',
        page: 1,
      },
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('DD/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      form: {
        date: {
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD 23:59:59'),
        },
        filters: {
          status: [],
          from: '',
          to: '',
          campaign: '',
          gte: moment().format('YYYY-MM-DD'),
          lte: moment().format('YYYY-MM-DD 23:59:59'),
          page: 1,
          sender: '',
          recipient: '',
        },
      },
    };
  },
  created() {
    this.getCampaign();
  },
  // Pai
  methods: {
    showFilter() {
      this.$refs.filter.toggle();
    },
    reload() {
      this.$refs.view.fetch(1);
      this.getCampaign();
    },
    applyFilter() {
      this.form.filters = this.filter;
      this.$nextTick(() => {
        this.reload();
      });
    },
    getCampaign() {
      WhatsService.getNewCampaigns({
        scheduled_at: {
          gte: this.form.date.gte,
          lte: this.form.date.lte,
        },
      }).then(
        (response) => {
          response.forEach((campaign) => {
            this.campaigns.push({
              ...campaign,
              name: campaign.name !== '' ? campaign.name : campaign.scheduled_at,
            });
          });
        },
        (error) => {
          this.$toast.show({
            title: 'Erro',
            content: 'Erro interno, por favor tente novamente mais tarde.',
            type: 'danger',
          });
        },
      );
    },
    cleanFilter() {
      this.$forceUpdate();
      this.filter = {
        carrier: [],
        status: [],
        from: '',
        to: '',
        segments: '',
        campaign: '',
        page: 1,
        sender: '',
        recipient: '',
      };
      this.form.filters = this.filter;
      this.$forceUpdate();
      this.$nextTick(() => {
        this.reload();
      });
    },
    changeDate(date) {
      if (date) {
        this.form.date.gte = date[0];
        this.form.date.lte = moment(date[1]).format('YYYY-MM-DD 23:59:59');

        this.$nextTick(() => {
          this.reload();
        });
      }
    },
    exportData(format) {
      this.$refs.view.exportData(format);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~primevue/resources/themes/saga-blue/theme.css';
@import '~primevue/resources/primevue.min.css';

.table-responsive {
  min-height: 150px;
}

/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}

.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form {
  position: absolute;
  right: 70px;
  width: 38px;
  opacity: 0;
}

.calendar_form:hover ~ .calendar_icon,
.calendar_form:focus-within ~ .calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 38px;
}

.card {
  box-shadow: none !important;
}

.nav-tabs {
  border-bottom: 4px solid #6B23DC3D;
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  border-bottom: solid 4px var(--clr-yup-purple);
}

.nav-tabs .nav-item {
  margin-bottom: -4px;
}

::v-deep .icon-calendar {
  color: var(--gray-font-color) !important;
}
</style>
